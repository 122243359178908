<template>
  <div class="page-content">
    <div style="background-color: #FFFFFF;padding: 20px;display: flex;justify-content: center;margin-top: 25px;">
      <el-input style="width: 500px;margin-right: 12px;" placeholder="关键词"></el-input>
      <el-button type="primary">检索</el-button>
    </div>
    <div v-if="data" style="background-color: #FFFFFF;padding: 40px 60px;margin-top: 25px;margin-bottom: 25px;">
      <div class="report" v-for="item in data.content" :key="item.data.urlMd5">
        <div class="report-left">
          <img src="../assets/images/ss/report-1.png">
        </div>
        <div class="report-right">
          <div>
            <a class="report-title" :href="item.data.url" target="_blank">{{item.data.title}}</a>
            <span class="report-type" v-if="item.data.industryName">{{item.data.industryName}}</span>
          </div>
          <div class="report-time">发布时间：{{item.data.publishTime.substring(0,10)}}</div>
          <div class="report-buttons">
            <div class="report-button">免费下载</div>
            <div class="report-button report-button-buy">购买下载</div>
            <div class="report-button report-button-fav">添加收藏</div>
            <div class="report-button report-button-view">查看原文</div>
          </div>
        </div>
      </div>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";

export default {
  name: "ReportList",
  data(){
    return {
      data:null
    }
  },
  created() {
    this.loadData(1)
  },
  methods:{
    async loadData(page){
      this.data=(await api.listHzReports({page:page-1,size:10})).data
    }
  }
}
</script>

<style scoped>

</style>
