<template>
  <div class="page-content">
    <div style="background-color: #FFFFFF;padding: 20px;display: flex;justify-content: center;margin-top: 25px;">
      <el-input style="width: 500px;margin-right: 12px;" placeholder="关键词"></el-input>
      <el-button type="primary">检索</el-button>
    </div>
    <div v-if="data" style="background-color: #FFFFFF;padding: 40px 60px;margin-top: 25px;margin-bottom: 25px;">
      <div class="report" v-for="item in data.content" :key="item.id">
        <div class="report-left">
          <img :src="item.faceUrl">
        </div>
        <div class="report-right">
          <div>
            <a class="report-title" :href="item.url" target="_blank">{{item.title}}</a>
          </div>
          <div class="report-source">原文标题：{{item.sourceTitle}}</div>
          <div class="report-abs">{{item.abs}}</div>
        </div>
      </div>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  name: "ReportList",
  data(){
    return {
      sourceData: {
        content: [
          {
            "id": 33,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2023/07/UFI-Barometer-31st-edition_COVER.jpg",
            "title": "全球展览晴雨表（2023 年 7 月）",
            "sourceTitle": "The Global Exhibition Barometer (July 2023)",
            "url": "https://www.ufi.org/wp-content/uploads/2023/07/31st_UFI_Global_Barometer_July_2023.pdf",
            "abs": "这项研究基于 2023 年 7 月结束的一项全球调查。它代表了全球展览业以及 19 个市场和地区的发展和前景的最新信息。"
          },
          {
            "id": 34,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2023/01/Cover_UFI-Barometer-30th-edition_1.jpg",
            "title": "全球展览晴雨表（2023 年 1 月）",
            "sourceTitle": "The Global Exhibition Barometer (January 2023)",
            "url": "https://www.ufi.org/wp-content/uploads/2023/01/30th_UFI_Global_Barometer_january_2023.pdf",
            "abs": "本研究基于 2023 年 1 月结束的一项全球调查。它代表了全球展览业以及 21 个市场和地区的发展和前景的最新信息。"
          },
          {
            "id": 35,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2021/07/UFI-barometer-27th-edition_cube_Cover.jpg",
            "title": "全球展览晴雨表（2022 年 7 月）",
            "sourceTitle": "The Global Exhibition Barometer (July 2022)",
            "url": "https://www.ufi.org/wp-content/uploads/2022/07/29th_UFI_Global_Barometer_july_2022.pdf",
            "abs": "本研究基于 2022 年 6 月结束的一项全球调查。它代表了全球展览业以及 28 个市场和地区的发展和前景的最新信息。"
          },
          {
            "id": 36,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2022/02/UFI-barometer-28th-edition_Cover.jpg",
            "title": "全球展览晴雨表（2022 年 2 月）",
            "sourceTitle": "The Global Exhibition Barometer (February 2022)",
            "url": "https://www.ufi.org/wp-content/uploads/2022/02/28th_UFI_Global_Barometer_february_2022.pdf",
            "abs": "本研究基于 2022 年 1 月结束的一项全球调查。它代表了全球展览业以及 29 个市场和地区的发展和前景的最新信息。"
          },
          {
            "id": 37,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2021/07/UFI-barometer-27th-edition_Cover.jpg",
            "title": "全球展览晴雨表（2021 年 7 月）",
            "sourceTitle": "The Global Exhibition Barometer (July 2021)",
            "url": "https://www.ufi.org/wp-content/uploads/2021/08/27th_UFI_Global_Barometer_july_2021_b.pdf",
            "abs": "本研究基于 2021 年 7 月结束的一项全球调查。它代表了全球展览业以及 28 个市场和地区的发展和前景的最新信息。"
          },
          {
            "id": 38,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2021/01/Cover_UFI-barometer-26th-edition.jpg",
            "title": "全球展览晴雨表（2021 年 1 月）",
            "sourceTitle": "The Global Exhibition Barometer (January 2021)",
            "url": "https://www.ufi.org/wp-content/uploads/2021/01/26th_UFI_Global_Barometer_january_2021.pdf",
            "abs": "这项研究基于 2021 年 1 月完成的一项全球调查。它代表了全球展览业以及 24 个国家和 5 个聚合区域的发展和前景的最新信息。"
          },
          {
            "id": 39,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2020/07/Cover_UFI-barometer-25th-edition.jpg",
            "title": "全球展览晴雨表（2020 年 7 月）",
            "sourceTitle": "The Global Exhibition Barometer (July 2020)",
            "url": "https://www.ufi.org/wp-content/uploads/2020/07/25th_UFI_Global_Barometer_july_2020_v1.pdf",
            "abs": "这项研究基于 2020 年 7 月结束的一项全球调查。它代表了全球展览业以及 20 个国家和 5 个聚合区域的发展和前景的最新信息。"
          },
          {
            "id": 40,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2020/02/Cover-for-24th_UFI_Global_Barometer_February_2020.jpg",
            "title": "全球展览晴雨表（2020 年 2 月）",
            "sourceTitle": "The Global Exhibition Barometer (February 2020)",
            "url": "https://www.ufi.org/wp-content/uploads/2020/02/24th_UFI_Global_Barometer_february_2020.pdf",
            "abs": "本研究基于 2020 年 1 月完成的一项全球调查。它代表了全球展览业以及 22 个特定国家和地区的发展和前景的最新信息。"
          },
          {
            "id": 41,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-23rd_UFI_Global_Barometer_July_2019.jpg",
            "title": "全球展览晴雨表（2019 年 7 月）",
            "sourceTitle": "The Global Exhibition Barometer (July 2019)",
            "url": "https://www.ufi.org/wp-content/uploads/2019/07/23rd_UFI_Global_Barometer_July_2019.pdf",
            "abs": "第23版“UFI全球展览晴雨表”调查以2019年7月结束的全球调查为基础。它代表了全球以及20个特定国家和地区展览业发展和前景的最新信息。区。"
          },
          {
            "id": 42,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-UFI_Explori_Global_Exhib_Insights-2017-members.jpg",
            "title": "全球参展商洞察（2017 年 11 月）",
            "sourceTitle": "Global Exhibitor Insights (November 2017)",
            "url": "https://www.ufi.org/wp-content/uploads/2017/11/UFI_Explori_Global_Exhib_Insights-2017-members.pdf",
            "abs": "仔细分析参展商的见解、经验和意图。分析的数据是对 40 多个国家/地区举办的 1,000 多场展会进行的展后调查的结果，涵盖各个行业和规模。"
          },
          {
            "id": 43,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-The_Exhibition_Industry_and_UN_SDGs_b.jpg",
            "title": "展览业与联合国可持续发展目标（2019 年 12 月）",
            "sourceTitle": "The Exhibition Industry and UN SDGs (December 2019)",
            "url": "https://www.ufi.org/wp-content/uploads/2020/01/The_Exhibition_Industry_and_UN_SDGs_Dec2019.pdf",
            "abs": "UFI为展览业推出了SDG数据库，将联合国17个可持续发展目标转化为行动。展览组织者和场馆运营商展示了该行业如何对环境和社会产生积极影响，同时主要通过多方利益相关者合作伙伴关系实现成本节约。"
          },
          {
            "id": 44,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2021/07/UFI-report-cover.jpg",
            "title": "展览业可持续发展现状（2021 年 7 月）",
            "sourceTitle": "Status of Sustainability in the Exhibition Industry (July 2021)",
            "url": "https://www.ufi.org/wp-content/uploads/2021/09/UFI_report_on_Sustainability_July-2021.pdf",
            "abs": "本报告汇总了 UFI 开展的多个可持续发展研究项目的调查结果和见解，重点关注参与者和展览行业的看法。这些调查结果适用于全球，并针对世界所有地区进行细分，将为我们行业正在进行和未来的项目提供见解和指导。"
          },
          {
            "id": 45,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2022/09/icon_global_economic_impact_2020.jpg",
            "title": "展览的全球经济影响（2022）",
            "sourceTitle": "Global Economic Impact of Exhibitions (2022)",
            "url": "https://www.ufi.org/wp-content/uploads/2022/09/OE-UFI_Global_Exhibitions_Impact_August_2022.pdf",
            "abs": ""
          },
          {
            "id": 46,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2020/06/icon_AFE_Economic_Impact_Study_june2020-1.jpg",
            "title": "西班牙展览的经济影响（2020 年 6 月）",
            "sourceTitle": "Economic Impact of Exhibitions in Spain (June 2020)",
            "url": "https://www.ufi.org/wp-content/uploads/2020/06/AFE_Economic_Impact_Study_june2020.pdf",
            "abs": ""
          },
          {
            "id": 47,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2022/09/icon_OE-UFI_EIS_Thailand_Report_2022.jpg",
            "title": "泰国展览的经济影响（2022 年 6 月）",
            "sourceTitle": "Economic Impact of Exhibitions in Thailand (June 2022)",
            "url": "https://www.ufi.org/wp-content/uploads/2022/09/OE-UFI_EIS_Thailand_Report_2022.pdf",
            "abs": ""
          },
          {
            "id": 48,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-2014_exhibiton_industry_statistics_b.jpg",
            "title": "全球展览业统计数据（2014年）",
            "sourceTitle": "The Global Exhibition Industry Statistics (2014)",
            "url": "https://www.ufi.org/wp-content/uploads/2016/01/2014_exhibiton_industry_statistics_b.pdf",
            "abs": "全球展览业统计数据（2014 年）——UFI 研究计划的结果，旨在提供全球展览市场场馆和组织者层面的可靠数据。"
          },
          {
            "id": 49,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-UFI_Best_Practices_In_Sustainability_Report.jpg",
            "title": "可持续发展最佳实践（2016 年 10 月）",
            "sourceTitle": "Best practices in Sustainability (October 2016)",
            "url": "https://www.ufi.org/wp-content/uploads/2016/10/UFI_Best_Practices_In_Sustainability_Report.pdf",
            "abs": "本报告提供了来自世界各地关于可持续发展各个方面的 40 多个案例研究。它展示了近年来 UFI 年度可持续发展奖计划的获奖和入围作品 - 向行业回馈有关最成功项目和实施的知识，以推动我们行业的可持续发展。"
          },
          {
            "id": 50,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-UFI_Report_on_Best_Practices_in_Marketing.jpg",
            "title": "UFI 营销最佳实践报告（2019 年 5 月）",
            "sourceTitle": "The UFI Report on Best Practices in Marketing (May 2019)",
            "url": "https://www.ufi.org/wp-content/uploads/2019/07/UFI_Report_on_Best_Practices_in_Marketing.pdf",
            "abs": "本报告提供了来自世界各地的 40 多个关于展览营销各个方面的案例研究。它既是热点话题的时间轴，也是营销领域的标尺。"
          },
          {
            "id": 51,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2022/01/World-Map-2022_Cover.jpg",
            "title": "UFI 世界展览场馆地图（2022 年版，2022 年 1 月）",
            "sourceTitle": "UFI World Map Of Exhibition Venues (2022 Edition, January 2022)",
            "url": "https://www.ufi.org/wp-content/uploads/2022/02/UFI_World_Map_of_Venues_2022.pdf",
            "abs": "该报告提供了全球概览和 21 个市场概况。该报告列出了所有 21 个市场的最大场馆，总容量超过 300,000 平方米的室内展览总面积。"
          },
          {
            "id": 52,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/UFI-World-Map-Image.png",
            "title": "UFI 配对调查（2012 年）",
            "sourceTitle": "UFI Survey on Matchmaking (2012)",
            "url": "https://www.ufi.org/wp-content/uploads/2016/01/ufi_matchmaking_survey_results_20121.pdf",
            "abs": "UFI 配对调查 (2012) - 由 UFI 营销委员会开展的一项调查，旨在调查展览行业内配对工具和服务的使用情况。"
          },
          {
            "id": 53,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-UFI_Best_Practices_Digital_Innovation.jpg",
            "title": "UFI 关于数字创新最佳实践的报告（2018 年 2 月）",
            "sourceTitle": "The UFI Report on Best Practices in Digital Innovation (February 2018)",
            "url": "https://www.ufi.org/wp-content/uploads/2018/02/UFI_Best_Practices_Digital_Innovation.pdf",
            "abs": "本报告提供了来自世界各地的 40 多个关于数字创新各个方面的案例研究。它通过收集最佳实践案例，在制定行业数字标准方面发挥着作用。"
          },
          {
            "id": 54,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-2009_ufi_insight_abstract_travel_accomodation.jpg",
            "title": "UFI 关于旅行和住宿的见解",
            "sourceTitle": "UFI Insights on Travel & Accommodation",
            "url": "https://www.ufi.org/wp-content/uploads/2016/01/2009_ufi_insight_abstract_travel_accomodation.pdf",
            "abs": "该文件旨在向 UFI 会员提供有关展览业当前在旅行和住宿方面面临的挑战的见解。"
          },
          {
            "id": 55,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-2009_ufi_insight_abstract_logistics.jpg",
            "title": "UFI 对物流的见解",
            "sourceTitle": "UFI Insights on Logistics",
            "url": "https://www.ufi.org/wp-content/uploads/2016/01/2009_ufi_insight_abstract_ Logistics.pdf",
            "abs": "该文件旨在向 UFI 会员提供有关展览业当前面临的物流挑战的见解。"
          },
          {
            "id": 56,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2020/10/JMIC_SDG_Cover_2020.jpg",
            "title": "JMIC + SDG 实现世界可持续发展目标（2020 年 10 月）",
            "sourceTitle": "JMIC + SDG’s\nMeeting the World’s Sustainable Development Goals (October 2020)",
            "url": "https://www.ufi.org/wp-content/uploads/2020/10/JMIC_SDG_Report_2020.pdf",
            "abs": "联合会议行业理事会（JMIC）扩展了UFI的倡议（针对展览行业），以期将其视野扩展到其他商务活动领域。 JMIC 报告基于来自全球 16 个国家的 43 份提交的材料。"
          },
          {
            "id": 57,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2022/04/Global_recovey_insight2022_cover.jpg",
            "title": "2021 年全球复苏洞察——复苏之路",
            "sourceTitle": "Global Recovery Insights 2021 – The road to recovery",
            "url": "https://www.ufi.org/wp-content/uploads/2022/04/UFI_Global_Recovery_Insights_2021.pdf",
            "abs": "本报告是《2021 年全球复苏洞察》报告的第三部分。最新数据反映了行业的乐观情绪，因为参展商和参观者对现场活动的需求已经恢复。"
          },
          {
            "id": 58,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2022/12/Cover-for-2021_euro_fairs_statistics.jpg",
            "title": "2021 年欧洲博览会统计（2022 年 11 月）",
            "sourceTitle": "Euro Fairs Statistic 2021 (November 2022)",
            "url": "https://www.ufi.org/wp-content/uploads/2022/12/2021_euro_fairs_statistics.pdf",
            "abs": "该年度报告代表了欧洲展览市场，包含来自13个国家的901个展览的审计数据和认证统计数据。"
          },
          {
            "id": 59,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2021/05/UFI-Explori_Global_Recovery_Insights_2020_Part_2.jpg",
            "title": "2020 年全球复苏洞察 – 第 2 部分 – 数字和混合活动的未来（2020 年 12 月）",
            "sourceTitle": "Global Recovery Insights 2020 – Part 2 – The future of digital and hybrid events (December 2020)",
            "url": "https://www.ufi.org/wp-content/uploads/2020/12/UFI-Explori_Global_Recovery_Insights_2020_Part_2.pdf",
            "abs": ""
          },
          {
            "id": 60,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2021/05/UFI-Explori_Global_Recovery_Insights_2020_Part_1.jpg",
            "title": "2020 年全球复苏洞察 – 第 1 部分 – 商业活动的前进方向（2020 年 10 月）",
            "sourceTitle": "Global Recovery Insights 2020 – Part 1 – The way forward for business events (October 2020)",
            "url": "https://www.ufi.org/wp-content/uploads/2020/10/UFI-Explori_Global_Recovery_Insights_2020_Part_1.pdf",
            "abs": ""
          },
          {
            "id": 61,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2021/11/euro_fair2019_revised_cover_V2.jpg",
            "title": "2019 年欧洲博览会统计（2021 年 11 月）",
            "sourceTitle": "Euro Fairs Statistic 2019 (November 2021)",
            "url": "https://www.ufi.org/wp-content/uploads/2021/11/euro_fair2019_revised_nov21.pdf",
            "abs": "该年度报告代表了欧洲展览市场，包含来自21个国家的2,416个展览的审计数据和认证统计数据。"
          },
          {
            "id": 62,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/11/Cover-for-2018_euro_fair_statistics.jpg",
            "title": "2018年欧洲博览会统计（2019年9月）",
            "sourceTitle": "Euro Fairs Statistic 2018 (September 2019)",
            "url": "https://www.ufi.org/wp-content/uploads/2019/11/2018_euro_fair_statistics.pdf",
            "abs": "该年度报告代表了欧洲展览市场，包含来自24个国家的2,673个展览的审计数据和认证统计数据。"
          },
          {
            "id": 63,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-UFI_Explori_Global_Visitor_report_2017.jpg",
            "title": "2016/17 年全球游客洞察（2016 年 11 月）",
            "sourceTitle": "Global Visitor Insight 2016/17 (November 2016)",
            "url": "https://www.ufi.org/wp-content/uploads/2017/11/UFI_Explori_Global_Visitor_report_2017.pdf",
            "abs": "最初的《全球观众洞察》研究仍然引人入胜：一份关注全球展览观众的报告。"
          },
          {
            "id": 64,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-2011-12_mea_report_executive_summary.jpg",
            "title": "2011-2012年中东和非洲展览业（2013年）",
            "sourceTitle": "The Exhibition Industry in the Middle East & Africa 2011-2012 (2013)",
            "url": "https://www.ufi.org/wp-content/uploads/2016/01/2011-12_mea_report_executive_summary-.pdf",
            "abs": "该报告由UFI中东和非洲区域办事处研究编写，涵盖44个国家。分别针对展览和场馆提供 2011 年和 2012 年的附加列表、比较、更新数据和信息。"
          },
          {
            "id": 65,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/12/EIA_Economic_Impact_Study_dec2019.jpg",
            "title": "英国展览的经济影响（2019年12月）",
            "sourceTitle": "Economic Impact of Exhibitions in the UK (December 2019)",
            "url": "https://www.ufi.org/wp-content/uploads/2019/12/EIA_Economic_Impact_Study_dec2019.pdf",
            "abs": ""
          },
          {
            "id": 66,
            "faceUrl": "https://www.ufi.org/wp-content/uploads/2019/10/Cover-for-UFI_Best_Practices_In_Sustainability_Report.jpg",
            "title": "可持续发展最佳实践（2016年10月）",
            "sourceTitle": "Best practices in Sustainability (October 2016)",
            "url": "https://www.ufi.org/wp-content/uploads/2016/10/UFI_Best_Practices_In_Sustainability_Report.pdf",
            "abs": "本报告提供了来自世界各地的40多个关于可持续发展各个方面的案例研究。它展示了近年来UFI年度可持续发展奖计划的获奖和入围作品，向行业回馈了关于推动我们行业可持续发展的最成功项目和实施的知识。"
          }
        ],
        totalElements: 34,
        number: 1,
      },
      data: {
        content: [],
        totalElements: 0,
        number: 1,
      },
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init() {
      await this.loadData(1)
    },
    async loadData(page){
      this.data.content=[]
      this.data.content=this.data.content.concat(this.sourceData.content)
      if(this.sourceData.totalElements>page*10){
        this.data.content=this.data.content.slice((page-1)*10,page*10)
      }else{
        this.data.content=this.data.content.slice((page-1)*10,this.sourceData.totalElements)
      }
      this.data.totalElements=this.sourceData.totalElements
      this.data.number=page-1
    },
  }
}
</script>

<style scoped>

</style>
